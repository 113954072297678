// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***************************/
/********* footer **********/
/***************************/

.footer-divider {
  height: 200px;
}

.footer-heart {
  display: inline-block;
  font-size: 2rem;
  animation: heartBeat 1.5s infinite;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  14% {
      transform: scale(1.3);
  }
  28% {
      transform: scale(1);
  }
  42% {
      transform: scale(1.3);
  }
  70% {
      transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/style.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B,4BAA4B;AAC5B,4BAA4B;;AAE5B;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,kCAAkC;AACpC;;AAyBA;EACE;IAEE,mBAAmB;EACrB;EACA;MAEI,qBAAqB;EACzB;EACA;MAEI,mBAAmB;EACvB;EACA;MAEI,qBAAqB;EACzB;EACA;MAEI,mBAAmB;EACvB;AACF","sourcesContent":["/***************************/\r\n/********* footer **********/\r\n/***************************/\r\n\r\n.footer-divider {\r\n  height: 200px;\r\n}\r\n\r\n.footer-heart {\r\n  display: inline-block;\r\n  font-size: 2rem;\r\n  animation: heartBeat 1.5s infinite;\r\n}\r\n\r\n@-webkit-keyframes heartBeat {\r\n  0% {\r\n    -webkit-transform: scale(1);\r\n    transform: scale(1);\r\n  }\r\n  14% {\r\n      -webkit-transform: scale(1.3);\r\n      transform: scale(1.3);\r\n  }\r\n  28% {\r\n      -webkit-transform: scale(1);\r\n      transform: scale(1);\r\n  }\r\n  42% {\r\n      -webkit-transform: scale(1.3);\r\n      transform: scale(1.3);\r\n  }\r\n  70% {\r\n      -webkit-transform: scale(1);\r\n      transform: scale(1);\r\n  }\r\n}\r\n\r\n@keyframes heartBeat {\r\n  0% {\r\n    -webkit-transform: scale(1);\r\n    transform: scale(1);\r\n  }\r\n  14% {\r\n      -webkit-transform: scale(1.3);\r\n      transform: scale(1.3);\r\n  }\r\n  28% {\r\n      -webkit-transform: scale(1);\r\n      transform: scale(1);\r\n  }\r\n  42% {\r\n      -webkit-transform: scale(1.3);\r\n      transform: scale(1.3);\r\n  }\r\n  70% {\r\n      -webkit-transform: scale(1);\r\n      transform: scale(1);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
